import React, { Fragment } from 'react';
import {
	styled,
	Input,
	Button,
	Tooltip,
	Checkbox,
	Select,
	ButtonGroup,
	WholeIcon,
	HalfIcon
} from "@lib/components";
import { FaMinusCircle, FaArrowUp, FaArrowDown, FaCopy } from "react-icons/fa";
import { arrayMove } from "react-sortable-hoc";
import { cloneDeepSafe } from "@lib/common";
import shortid from "shortid";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../../mobx/components";
import _ from "lodash"
const autobind = require("class-autobind").default;

const wpos_status: boolean = true;

type ItemType = T.Schema.Restaurant.Menu.RestaurantOptionSetOption;
type ListType = ItemType[];
type HandleChange = (
	i: number,
	key: keyof ItemType,
	value: string | number | null | { [key: string]: string | number | T.Schema.Restaurant.Menu.RestaurantOptionSetOptionPointPrices }
) => void;
type HandleSetDefault = (i: number, quantity: 1 | 0) => void;
type HandleByIndex = (i: number) => void;
type HandleMove = (index: number, direction: 1 | -1) => void;

interface Props {
	values: T.Schema.Restaurant.Menu.RestaurantOptionSet;
	option_sets: T.Schema.Restaurant.Menu.RestaurantOptionSet[];
	setFieldValue: (key: string, value: any) => void;
	defaultEnabled?: boolean;
	restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	setRestaurantStock: (stock: T.Schema.RestaurantMenuStock.Schema) => void;
}
interface TableDataProps {
	items: ListType;
	change: HandleChange;
	remove: HandleByIndex;
	copy: HandleByIndex;
	move: HandleMove;
	setDefault: HandleSetDefault;
	variablePriceOptionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet | null;
	showVariablePrice: boolean;
	defaultEnabled?: boolean;
	restaurantStock: T.Schema.RestaurantMenuStock.Schema;
	setRestaurantStock: (stock: T.Schema.RestaurantMenuStock.Schema) => void;
	usingPoints?: boolean;
	selectedOptionId?: string;
	currencySymbol?: string;
}

const Table = styled.table`
  background: white;
  thead {
    th {
      white-space: nowrap;
    }
  }
  td {
    vertical-align: middle;
  }
`;
const rowDataProps = { className: "border-white-20 p-lr-1 p-tb-2" };

@observer
class TableData extends React.Component<TableDataProps> {
	render() {
		const {
			items,
			change,
			remove,
			copy,
			move,
			setDefault,
			defaultEnabled,
			variablePriceOptionSet,
			showVariablePrice,
			restaurantStock,
			setRestaurantStock,
			usingPoints,
			selectedOptionId,
			currencySymbol,
		} = this.props;

		return (
			<>
				{items.map((item, i) => {
					const stock =
						typeof restaurantStock.option_set_options[item._id] === "number"
							? restaurantStock.option_set_options[item._id]
							: "";
					return (
						<tr key={`trow-${item._id}-${i}`} className="bg-white border-white-20">
							<td
								className="border-white-20 cursor text-center"
								onClick={() => move(i, -1)}
							>
								<Tooltip
									text="Move Up"
									width={65}
									position="top"
									disable={i !== 0}
								>
									<FaArrowUp />
								</Tooltip>
							</td>

							<td
								className="border-white-20 cursor text-center"
								onClick={() => move(i, 1)}
							>
								<Tooltip
									text="Move Down"
									width={85}
									position="top"
									disable={i !== 0}
								>
									<FaArrowDown />
								</Tooltip>
							</td>

							<td className="border-white-20">
								<div className="flex-center">
									<Input
										type="text"
										className="no-round no-border"
										value={item.name}
										placeholder={"Name"}
										required={true}
										onChange={(e: any) => change(i, "name", e.target.value)}
									/>
								</div>
							</td>
							<td className="border-white-20">
								<div className="flex-center">
									<Input
										type="text"
										className="no-round no-border"
										value={item.print_name || ''}
										placeholder={"Print Name"}
										onChange={(e: any) => change(i, "print_name", e.target.value)}
									/>
								</div>
							</td>
							{!showVariablePrice && !usingPoints && (
								<td className="border-white-20">
									<Input
										type="number"
										min={0}
										step={0.01}
										className="no-round no-border"
										placeholder={currencySymbol}
										value={item.price || ""}
										onWheel={(e) => e.currentTarget.blur()}
										onChange={(e: any) => change(i, "price", e.target.value)}
									/>
								</td>
							)}

							{!showVariablePrice && usingPoints && (<>
								<td className="border-white-20">
									<Input
										type="number"
										min={0.5}
										max={100}
										step={0.01}
										onWheel={(e) => e.currentTarget.blur()}
										className="no-round no-border"
										placeholder={currencySymbol}
										onWheel={(e) => e.currentTarget.blur()}
										value={item.price && item.price.whole && item.price.whole.points ? item.price.whole.points : ""}
										onChange={(e: any) => {
											const newValue = {
												whole: {
													points: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
								<td className="border-white-20">
									<Input
										type="number"
										min={0}
										max={100}
										step={0.01}
										onWheel={(e) => e.currentTarget.blur()}
										className="no-round no-border"
										placeholder={currencySymbol}
										onWheel={(e) => e.currentTarget.blur()}
										value={item.price && item.price.whole && item.price.whole.single ? item.price.whole.single : ""}
										onChange={(e: any) => {
											const newValue = {
												whole: {
													single: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
								<td className="border-white-20">
									<Input
										type="number"
										min={0}
										max={100}
										step={0.01}
										className="no-round no-border"
										onWheel={(e) => e.currentTarget.blur()}
										placeholder={currencySymbol}
										value={item.price && item.price.whole && item.price.whole.double ? item.price.whole.double : ""}
										onChange={(e: any) => {
											const newValue = {
												whole: {
													double: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
								<td className="border-white-20">
									<Input
										type="number"
										min={0.5}
										max={100}
										step={0.01}
										onWheel={(e) => e.currentTarget.blur()}
										className="no-round no-border"
										placeholder={""}
										value={item.price && item.price.half && item.price.half.points ? item.price.half.points : ""}
										onChange={(e: any) => {
											const newValue = {
												half: {
													points: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
								<td className="border-white-20">
									<Input
										type="number"
										min={0}
										max={100}
										step={0.01}
										onWheel={(e) => e.currentTarget.blur()}
										className="no-round no-border"
										placeholder={currencySymbol}
										value={item.price && item.price.half && item.price.half.single ? item.price.half.single : ""}
										onChange={(e: any) => {
											const newValue = {
												half: {
													single: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
								<td className="border-white-20">
									<Input
										type="number"
										min={0}
										max={100}
										step={0.01}
										onWheel={(e) => e.currentTarget.blur()}
										className="no-round no-border"
										placeholder={currencySymbol}
										value={item.price && item.price.half && item.price.half.double ? item.price.half.double : ""}
										onChange={(e: any) => {
											const newValue = {
												half: {
													double: e.target.value ? Number(e.target.value) : e.target.value
												}
											};
											const price = item.price || {};
											_.merge(price, newValue);
											change(i, "price", price);
										}}
									/>
								</td>
							</>)}

							{showVariablePrice && !usingPoints &&
								variablePriceOptionSet!.options.map((o) => (
									<td key={`no-point-${o._id}-tdata`} className="border-white-20">
										<Input
											type="number"
											min={0}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={currencySymbol}
											value={(item.prices && item.prices[o._id] ? item.prices[o._id] : "")}
											onChange={(e: any) => {
												const variable_prices = item.prices || {};
												variable_prices[o._id] = e.target.value;
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
								))}

							{showVariablePrice && usingPoints && variablePriceOptionSet.options &&
								variablePriceOptionSet!.options.map((o, j) => (<Fragment key={`with-point-${o._id}-tdata`}>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0.5}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={""}
											value={item.prices[o._id] && item.prices[o._id].whole && item.prices[o._id].whole.points ? item.prices[o._id].whole.points : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														whole: {
															points: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={currencySymbol}
											value={item.prices[o._id] && item.prices[o._id].whole && item.prices[o._id].whole.single ? item.prices[o._id].whole && item.prices[o._id].whole.single : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														whole: {
															single: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={currencySymbol}
											value={item.prices[o._id] && item.prices[o._id].whole && item.prices[o._id].whole.double ? item.prices[o._id].whole.double : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														whole: {
															double: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0.5}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={""}
											value={item.prices[o._id] && item.prices[o._id].half && item.prices[o._id].half.points ? item.prices[o._id].half.points : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														half: {
															points: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={currencySymbol}
											value={item.prices[o._id] && item.prices[o._id].half && item.prices[o._id].half.single ? item.prices[o._id].half.single : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														half: {
															single: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
									<td className="border-white-20" style={o._id === selectedOptionId ? undefined : { display: "none" }}>
										<Input
											type={o._id === selectedOptionId ? "number" : "hidden"}
											min={0}
											max={100}
											step={0.01}
											onWheel={(e) => e.currentTarget.blur()}
											className="no-round no-border"
											placeholder={currencySymbol}
											value={item.prices[o._id] && item.prices[o._id].half && item.prices[o._id].half.double ? item.prices[o._id].half.double : ""}
											onChange={(e: any) => {
												const newValue = {
													[o._id]: {
														half: {
															double: e.target.value ? Number(e.target.value) : e.target.value
														}
													}
												};
												const variable_prices = item.prices || {};
												_.merge(variable_prices, newValue);
												change(i, "prices", variable_prices);
											}}
										/>
									</td>
								</Fragment>
								))}

							<td className="border-white-20" style={{ display: "none" }}>
								<Input
									type="number"
									min={0}
									step={1}
									className="no-round no-border"
									placeholder={""}
									value={item.status === "not-available" ? "" : stock}
									disabled={item.status === "not-available"}
									onChange={(e: any) => {
										let value: "" | number;
										try {
											value =
												e.target.value === "" ? "" : parseInt(e.target.value);
										} catch (e) {
											console.log(e);
											return;
										}

										const rs = cloneDeepSafe(restaurantStock);

										if (value === "") {
											delete rs.option_set_options[item._id];
										} else {
											rs.option_set_options[item._id] = value;
										}

										setRestaurantStock(rs);
									}}
								/>
							</td>

							<td className="border-white-20 p-lr-2">
								<Checkbox
									id={`${i}-unavailable`}
									checked={item.status === "not-available"}
									onChange={() => {
										change(
											i,
											"status",
											item.status === "not-available" ? null : "not-available"
										);
										if (item.quantity === 1) {
											setDefault(i, 0);
										}
									}}
								/>
							</td>

							{defaultEnabled && (
								<td className="border-white-20 p-lr-2">
									<Checkbox
										id={`${i}-defaultos`}
										checked={item.quantity === 1}
										onChange={() => setDefault(i, item.quantity === 1 ? 0 : 1)}
									/>
								</td>
							)}

							{wpos_status && (
								<td className="border-white-20">
									<div className="flex-center">
										<Input
											type="number"
											className="no-round no-border"
											value={item.integrations?.pushpos?.item_id || ''} // Safely access item_id
											placeholder={"PushPOS Item Id"}
											required={false}
											onChange={(e: any) => {
												const updatedValue = parseInt(e.target.value, 10);

												// Ensure pushpos exists before updating
												const updatedIntegrations = {
													...item.integrations,
													pushpos: {
														...item.integrations?.pushpos,
														item_id: updatedValue,
													},
												};

												change(i, "integrations", updatedIntegrations); // Pass the updated integrations object
											}}
										/>
									</div>
								</td>
							)}

							<td
								className="border-white-20 cursor text-center"
								onClick={() => remove(i)}
							>
								<Tooltip
									text="Delete"
									width={65}
									position="top"
								>
									<FaMinusCircle />
								</Tooltip>
							</td>
							<td
								className="border-white-20 cursor text-center"
								onClick={() => copy(i)}
							>
								<Tooltip
									text="Copy"
									width={55}
									position="top"
								>
									<FaCopy />
								</Tooltip>
							</td>
						</tr>
					);
				})}
			</>
		);
	}
}

@inject("store")
@observer
export class FieldOptionSetOptions extends MobxComponent<
	Props,
	{
		priceView: string;
		selectedOptionId: string;
	}
> {
	constructor(props: Props) {
		super(props);
		autobind(this);
		this.state = {
			priceView: props.values.variable_price_ref ? "variable" : "standard",
			selectedOptionId: this.getFirstVariableOptionSetOptionId(props)
		};
	}

	remove: HandleByIndex = (i) => {
		const values = [...this.props.values.options];
		values.splice(i, 1);
		this.props.setFieldValue("options", values);
	};

	copy: HandleByIndex = (i) => {
		const values = cloneDeepSafe(this.props.values.options);

		const newValue = {
			...JSON.parse(JSON.stringify(values[i])),
			_id: shortid.generate(),
			name: "",
			quantity: 0,
		};

		values.splice(i + 1, 0, newValue);
		this.props.setFieldValue("options", values);
	}

	change: HandleChange = (i, key, value) => {
		const values = [...this.props.values.options];
		// @ts-ignore
		values[i][key] = value;
		this.props.setFieldValue("options", values);
	};
	move: HandleMove = (index, direction) => {
		const values = [...this.props.values.options];

		if (
			(index === 0 && direction === -1) ||
			(index === values.length - 1 && direction === 1)
		) {
			return;
		}

		this.props.setFieldValue(
			"options",
			arrayMove(values, index, index + direction)
		);
	};
	setDefault: HandleSetDefault = (i, quantity) => {
		this.props.setFieldValue(
			"options",
			this.props.values.options.map((item, index) => ({
				...item,
				quantity: i === index ? quantity : 0,
				status: i === index ? null : item.status,
			}))
		);
	};

	componentDidUpdate(prevProps: Readonly<Props>): void {
		if (
			this.props.values.variable_price_ref &&
			this.props.values.variable_price_ref !==
			prevProps.values.variable_price_ref
		) {
			const values = cloneDeepSafe(this.props.values.options);
			for (let i = 0; i < values.length; i++) {
				values[i].prices = {};
			}
			this.props.setFieldValue("options", values);
		}
	}

	getFirstVariableOptionSetOptionId(props: Readonly<Props>): string {
		const variablePriceOptionSet = !props.values.variable_price_ref ? null : this.props.option_sets.find((os) => os._id === props.values.variable_price_ref) || null;

		return variablePriceOptionSet && variablePriceOptionSet.options && variablePriceOptionSet.options.length > 0 ? variablePriceOptionSet.options[0]._id : "";
	}

	formatDecimalPlaces(value: number) {
		return value == 0 ? value : Number(value.toFixed(2))
	}

	autoCalculate(): void {
		// get all option set options (#main, #variable);
		// loop main and using whole points and whole single values, autocalculate the rest
		// subloop #variable and do the same
		// NOTE: I can't do type checking here :(
		const { priceView } = this.state;
		const { values, option_sets, setFieldValue } = this.props;
		const { variable_price_ref, using_points } = values;

		const variablePriceOptionSet = !variable_price_ref ? null : option_sets.find((os) => os._id === variable_price_ref) || null;
		const showVariablePrice = priceView === "variable" && !!variablePriceOptionSet;

		if (using_points && values.options) {
			const optionSetOptions = values.options;
			const newOptionSetOptions = optionSetOptions.map((option) => {
				if (!showVariablePrice && option.price) {
					console.log(`AUTO CALCULATING STANDARD PRICING`);
					// calculate points
					const whole_points = option.price.whole && option.price.whole.points ? option.price.whole.points : 0;
					const half_points = whole_points / 2;
					// calculate prices
					const whole_single_value = this.formatDecimalPlaces(option.price.whole && option.price.whole.single ? option.price.whole.single : 0);
					const whole_double_value = this.formatDecimalPlaces(whole_single_value * 2);
					const half_single_value = this.formatDecimalPlaces(whole_single_value / 2);
					const half_double_value = this.formatDecimalPlaces(whole_single_value);

					const newOptionValue = {
						whole: {
							points: whole_points,
							single: whole_single_value,
							double: whole_double_value
						},
						half: {
							points: half_points,
							single: half_single_value,
							double: half_double_value
						}
					};
					option.price = newOptionValue;
				} else if (showVariablePrice && option.prices) {
					console.log(`AUTO CALCULATING VARIABLE PRICING`);
					// TODO: autocalculate variable pricing
					const newOptionPrices = option.prices;
					_.forIn(newOptionPrices, (price, variableOptionSetId) => {

						// calculate points
						const whole_points = price.whole && price.whole.points ? price.whole.points : 0;
						const half_points = whole_points / 2;
						// calculate prices
						const whole_single_value = this.formatDecimalPlaces(price.whole && price.whole.single ? price.whole.single : 0);
						const whole_double_value = this.formatDecimalPlaces(whole_single_value * 2);
						const half_single_value = this.formatDecimalPlaces(whole_single_value / 2);
						const half_double_value = this.formatDecimalPlaces(whole_single_value);

						const newOptionValue = {
							whole: {
								points: whole_points,
								single: whole_single_value,
								double: whole_double_value
							},
							half: {
								points: half_points,
								single: half_single_value,
								double: half_double_value
							}
						};
						newOptionPrices[variableOptionSetId] = newOptionValue;
					});
					option.prices = newOptionPrices;
				}
				return option;
			});
			setFieldValue("options", newOptionSetOptions);
		}
	}

	render() {
		const { priceView, selectedOptionId } = this.state;
		const { values, defaultEnabled, option_sets, setFieldValue } = this.props;
		const { variable_price_ref, using_points } = values;

		const currencySymbol = this.injected.store.restaurant?.settings.region.currency.symbol || "$";		

		const variablePriceOptionSet = !variable_price_ref ? null : option_sets.find((os) => os._id === variable_price_ref) || null;
		const showVariablePrice = priceView === "variable" && !!variablePriceOptionSet;
		const sortVariablePriceOptions = (options: T.Schema.Restaurant.Menu.RestaurantOptionSetOption[]) => {
			const typeName = [
			  options.filter((os) => os.name.length < 14),
			  options.filter((os) => os.name.length >= 14 && os.name.length < 23),
			  options.filter((os) => os.name.length >= 23)
			];
		  
			const sortedOptions = [];
		  
			typeName[2].forEach((item) => sortedOptions.push([item]));
		  
			while (typeName[1].length > 0) {
			  const group = [typeName[1].shift()];
			  if (typeName[1].length > 0) group.push(typeName[1].shift());
			  sortedOptions.push(group);
			}
		  
			while (typeName[0].length >= 3) {
			  sortedOptions.push([typeName[0].shift(), typeName[0].shift(), typeName[0].shift()]);
			}
		  
			while (typeName[0].length > 0) {
			  const group = [typeName[0].shift()];
			  if (typeName[0].length > 0) group.push(typeName[0].shift());
			  sortedOptions.push(group);
			}
		  
			return sortedOptions.flat();
		  };

		return (
			<>
				<div className="flex-line flex-center-v flex-wrap m-b-3">

					<Select
						style={{ width: "auto", height: "30px" }}
						className="flex-grow"
						value={values.variable_price_ref}
						onChange={(e) => {
							if (e.target.value === "") {
								this.setState({ priceView: "standard" });
							}
							setFieldValue("variable_price_ref", e.target.value);
						}}
						options={[
							{ value: "", label: "No Variable Price" },
							...option_sets
								.filter((os) => {
									return (
										os._id !== values._id &&
										!os.conditions.multi_select &&
										!os.conditions.quantity_select
									);
								})
								.map((os) => ({ value: os._id, label: os.name })),
						]}
					/>

					{values.variable_price_ref && (
						<ButtonGroup
							size="xs"
							width={100}
							className="m-l-1"
							selected={priceView}
							onSelect={(v) => this.setState({ priceView: v.value as string })}
							options={[
								{ value: "standard", name: `Regular (${currencySymbol})` },
								{ value: "variable", name: `Variable (${currencySymbol})` },
							]}
						/>
					)}

				</div>

				<div className="flex-line flex-center-v flex-center-h flex-wrap m-b-3">
					<Button
						size="xs"
						type="button"
						color="primary-inverse"
						className="m-r-1"
						onClick={() => {
							setFieldValue("options", [
								...values.options,
								{
									_id: shortid.generate(),
									name: "",
									price: "",
									quantity: 0,
									prices: {},
								} as T.Schema.Restaurant.Menu.RestaurantOptionSetOption,
							]);
						}}
					>
						Add Option
					</Button>

					{using_points && (
						<Button
							size="xs"
							type="button"
							color="primary-inverse"
							className="m-l-1"
							onClick={() => { this.autoCalculate() }}
						>
							Auto Calculate
						</Button>
					)}
				</div>

				<div className="flex-line flex-center-v flex-center-h flex-wrap m-b-3">
					{values.variable_price_ref && priceView === "variable" && variablePriceOptionSet && variablePriceOptionSet.options && using_points && (
						<ButtonGroup
							size="xs"
							width={160}
							className="m-l-1"
							buttonClassName="p-lr-2"
							selected={selectedOptionId}
							onSelect={(v) => this.setState({ selectedOptionId: v.value as string })}
							options={
								variablePriceOptionSet &&  sortVariablePriceOptions(variablePriceOptionSet.options)
									.map((os: any) => ({ value: os._id, name: os.name }))
							}
							buttonWidth
					/>
					)}
				</div>

				<Table className="width100">
					<thead>
						<tr className="text-center">
							<th
								{...rowDataProps}
								style={{ minWidth: "20px" }}
							/>
							<th
								{...rowDataProps}
								style={{ minWidth: "20px" }}
							/>
							<th {...rowDataProps}>Name</th>
							<th {...rowDataProps}>Print Name</th>
							{!showVariablePrice && !using_points && <th {...rowDataProps}>Price ({currencySymbol})</th>}
							{showVariablePrice && !using_points &&
								variablePriceOptionSet!.options.map((o, i) => (
									<th
										key={`no-points-theader-${o._id}`}
										{...rowDataProps}
										style={{ whiteSpace: "normal" }}
									>
										{o.name} ({currencySymbol})
									</th>
								))}

							{!showVariablePrice && using_points && (<>
								<th {...rowDataProps}><WholeIcon /> Points</th>
								<th {...rowDataProps}><WholeIcon /> x1</th>
								<th {...rowDataProps}><WholeIcon /> x2</th>
								<th {...rowDataProps}><HalfIcon /> Points</th>
								<th {...rowDataProps}><HalfIcon /> x1</th>
								<th {...rowDataProps}><HalfIcon /> x2</th>
							</>)}

							{showVariablePrice && using_points &&
								variablePriceOptionSet!.options.map((o, i) => (<Fragment key={`with-points-theader-${o._id}-${i}`}>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><WholeIcon /> Points</th>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><WholeIcon /> x1</th>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><WholeIcon /> x2</th>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><HalfIcon /> Points</th>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><HalfIcon /> x1</th>
									<th {...rowDataProps} style={o._id === selectedOptionId ? undefined : { display: "none" }}><HalfIcon /> x2</th>
								</Fragment>
								))}
							<th {...rowDataProps} style={{ display: "none" }}># in Stock</th>
							<th {...rowDataProps} style={{ maxWidth: "50px", whiteSpace: "normal" }}>No Stock</th>
							{defaultEnabled && <th {...rowDataProps} style={{ maxWidth: "60px" }}>Default</th>}
							{wpos_status && <th {...rowDataProps}>PushPOS Item Id</th>}
							<th {...rowDataProps} style={{ minWidth: "20px" }} />
							<th {...rowDataProps} style={{ minWidth: "20px" }} />
						</tr>
					</thead>
					<tbody>
						<TableData
							items={values.options}
							change={this.change}
							remove={this.remove}
							copy={this.copy}
							move={this.move}
							setDefault={this.setDefault}
							defaultEnabled={defaultEnabled}
							showVariablePrice={showVariablePrice}
							variablePriceOptionSet={variablePriceOptionSet}
							restaurantStock={this.props.restaurantStock}
							setRestaurantStock={this.props.setRestaurantStock}
							usingPoints={using_points}
							selectedOptionId={selectedOptionId}
							currencySymbol={currencySymbol}
						/>
					</tbody>
				</Table>
			</>
		);
	}
}